import React from 'react';
import { FiShare2 } from 'react-icons/fi';
import html2canvas from 'html2canvas'; // Import html2canvas
import { titleCase } from '../utils/index';

const Moments = (props) => {
  const shareDivAsImage = async () => {
    const element = document.getElementById('momentsDiv');

    // Capture the div as an image
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL('image/png');

    // Check if Web Share API is supported
    if (navigator.share) {
      try {
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], 'shared-image.png', { type: 'image/png' });

        await navigator.share({
          title: 'Litudian Shopping Moments',
          text: 'Check out my shopping moments!',
          files: [file],
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      // Fallback: open the image in a new tab
      const newWindow = window.open();
      newWindow.document.write(`<img src="${image}" alt="Shared Image"/>`);
    }
  };

  const { first_shipment, first_shipment_image, largest_order, largest_order_image, fastest_shipment, fastest_shipment_image } = props;

  return (
    <div
      id="momentsDiv"
      className="relative flex flex-col items-center justify-center h-screen w-full text-white font-bold p-4 bg-cover bg-center"
      style={{ backgroundImage: `url('moments.svg')` }}
    >
      

      <div className="text-center">
        <h1 className='font-semibold text-3xl md:text-6xl text-[#ff4c00] mb-2 md:mb-3'>Your Shopping Moments</h1>
        <p className='text-orange-900 text-sm md:text-2xl'>Remember these moments?</p>

        <div className='flex flex-col md:flex-row justify-center w-full md:w-[80%] p-4 md:p-6 mx-auto space-y-4 md:space-y-0 md:space-x-8 mt-2 md:mt-4 mb-2 md:mb-4'>
          <div className='space-y-2 md:space-y-4'>
        
            <div className='flex text-center justify-center items-center'>
            <div className='relative mr-8 items-center text-center'>
              {/* Background circles */}
              <div className="absolute w-24 h-24 bg-orange-200 rounded-full -left-8 -top-8"></div>
              <div className="absolute w-24 h-24 bg-orange-200 rounded-full -right-8 -bottom-8"></div>
              {/* Image container with border */}
              <div className="relative w-28 h-28 bg-white rounded-full border-4 border-orange-500 flex items-center justify-center overflow-hidden z-10">
                <img src={first_shipment_image} alt="Category" className="w-28 h-28 object-cover" />
              </div>
            </div>
            <div>
            <p className='text-lg md:text-3xl text-orange-900'>Your first shipment</p>
            <p className='text-white text-lg md:text-2xl'>{titleCase(first_shipment)}</p>
            </div>
            </div>
          </div>
          <div className='space-y-2 md:space-y-4'>
            <div className='flex text-center justify-center items-center'>
            <div className='relative mr-8 items-center text-center'>
              {/* Background circles */}
              <div className="absolute w-24 h-24 bg-orange-200 rounded-full -left-8 -top-8"></div>
              <div className="absolute w-24 h-24 bg-orange-200 rounded-full -right-8 -bottom-8"></div>
              {/* Image container with border */}
              <div className="relative w-28 h-28 bg-white rounded-full border-4 border-orange-500 flex items-center justify-center overflow-hidden z-10">
                <img src={largest_order_image} alt="Category" className="w-28 h-28 object-cover" />
              </div>
            </div>
            <div>
            <p className='text-lg md:text-3xl text-orange-900'>Your largest order</p>
            <p className='text-white text-lg md:text-2xl'>{titleCase(largest_order)}</p>
            </div>
            </div>
          </div>
          <div className='space-y-2 md:space-y-4'>
           
            <div className='flex text-center justify-center items-center'>
            <div className='relative mr-8 items-center text-center'>
              {/* Background circles */}
              <div className="absolute w-24 h-24 bg-orange-200 rounded-full -left-8 -top-8"></div>
              <div className="absolute w-24 h-24 bg-orange-200 rounded-full -right-8 -bottom-8"></div>
              {/* Image container with border */}
              <div className="relative w-28 h-28 bg-white rounded-full border-4 border-orange-500 flex items-center justify-center overflow-hidden z-10">
                <img src={fastest_shipment_image} alt="Category" className="w-28 h-28 object-cover" />
              </div>
            </div>
            <div>
            <p className='text-lg md:text-3xl text-orange-900'>Your fastest shipment</p>
            <p className='text-white text-lg md:text-2xl'>{titleCase(fastest_shipment)}</p>
            </div>
              
            </div>
          </div>
        </div>
        <p className='text-orange-900 text-sm md:text-2xl mt-10 md:mt-20'>Each shopping tells a story</p>
      </div>
         {/* Share Icon */}
         <div className=" absolute bottom-4 mx-auto z-10">
        <button 
          onClick={shareDivAsImage} 
          className="text-white bg-black bg-opacity-50 rounded-full p-2">
          <FiShare2 size={24} />
        </button>
      </div>
    </div>
  );
}

export default Moments;
