import React from 'react';
import html2canvas from 'html2canvas';
import { FiShare2 } from 'react-icons/fi'; // Importing the share icon

const Stats = (props) => {
  const shareDivAsImage = async () => {
    const element = document.getElementById('statsDiv');

    // Capture the div as an image
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL('image/png');

    // Check if Web Share API is supported
    if (navigator.share) {
      try {
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], 'shared-image.png', { type: 'image/png' });

        await navigator.share({
          title: 'Litudian Wrapped Stats',
          text: 'Check out my shopping stats!',
          files: [file],
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      // Fallback: open the image in a new tab
      const newWindow = window.open();
      newWindow.document.write(`<img src="${image}" alt="Shared Image"/>`);
    }
  };

  const { shipping_stats = [], shipping_destination } = props;

  return (
    <div
      id="statsDiv"
      className="relative flex flex-col items-center justify-center h-screen w-full text-white font-bold p-4 bg-cover bg-center"
      style={{ backgroundImage: `url('favorites.svg')` }}
    >
      <div className="text-center">
        <div className="mt-8">
          <h1 className="font-semibold text-4xl md:text-6xl text-[#ff4c00] mb-3">
            Your Shopping Stats
          </h1>
          <p className="font-semibold text-base md:text-m text-white">
            in the last 3 years.
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center w-[90%] md:w-[80%] p-6 mx-auto space-y-4 md:space-y-0 md:space-x-10 mt-4 mb-4">
          <div className="text-center text-white">
            <h1 className="text-6xl md:text-8xl font-semibold text-white">
              {shipping_stats.some((item) =>
                item.hasOwnProperty("items_shipped_last_three_years")
              )
                ? shipping_stats[0]["items_shipped_last_three_years"]
                : "N/A"}
            </h1>
            <p className="text-xl md:text-2xl">Number of items shipped</p>
          </div>
          <div className="text-center text-white">
            <h1 className="text-6xl md:text-8xl font-semibold text-white">
              {shipping_stats.some((item) =>
                item.hasOwnProperty("average_delivery_time")
              )
                ? shipping_stats[1]["average_delivery_time"]
                : "N/A"}
            </h1>
            <p className="text-xl md:text-2xl">
              Average shipping time in days.
            </p>
          </div>
        </div>

        <div className="text-center">
          <h1 className="text-xl md:text-2xl font-semibold text-orange-500">
            Frequent shipping destination:
          </h1>
          <p className="text-3xl md:text-4xl text-white">{shipping_destination}</p>
        </div>
      </div>

      {/* Share Icon */}
      <div className=" absolute bottom-4 mx-auto z-10">
        <button
          onClick={shareDivAsImage}
          className="text-white bg-black bg-opacity-50 rounded-full p-2"
        >
          <FiShare2 size={24} />
        </button>
      </div>
    </div>
  );
}

export default Stats;
