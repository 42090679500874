import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Intro from "./intro";
import Intro2 from "./intro2";
import Persona from "./persona";
import Categoriez from "./categories";
import Favorites from "./favorites";
import Stats from "./stats";
import Moments from "./moments";
import Highlights from "./highlights";
import Feedback from "./feedback";
import Thankyou from "./thankyou";
import { instance } from "../helpers/instance";
import { useParams } from "react-router-dom";

const LitudianWrapped = () => {
  // States
  const [config, setConfig] = useState(null);

  // Hooks
  const { id } = useParams();

  // Effects
  useEffect(() => {
    getWrappedData();
  }, []);

  // Functions
  const getWrappedData = async () => {
    try {
      if (!id) return (window.location.href = "/");
      let response = await instance.get(`wrapped/${id}`);
      if (response?.data) setConfig(response.data);
    } catch (error) {
      if (error?.response?.status && error?.response?.status === 400) {
        return (window.location.href = "/");
      }
    }
  };

  // Constants
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  return (
    <div className="App bg-amber-950">
      <div className="relative h-screen w-full overflow-hidden">
        <Slider {...settings}>
          <div className="flex items-center justify-center h-screen w-full">
            <Intro name={config?.name || ""} />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Intro2 />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Persona persona={config?.persona || ""} />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Categoriez categories={config?.top_categories || []} />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Favorites
              products={config?.favorite_products || []}
              persona={config?.persona || ""}
            />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Stats
              shipping_stats={config?.shipping_stats || []}
              shipping_destination={config?.fav_delivery_location || "Nairobi"}
            />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Moments
              first_shipment={config?.first_shipment.name || ""}
              first_shipment_image={config?.first_shipment.image || ""}
              largest_order={config?.largest_order.name || ""}
              largest_order_image={config?.largest_order.image || ""}
              fastest_shipment={config?.fastest_shipment.name || ""}
              fastest_shipment_image={config?.fastest_shipment.image || ""}
            />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Highlights categories={config?.top_litudian_categories || []} />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Feedback />
          </div>
          <div className="flex items-center justify-center h-screen w-full">
            <Thankyou promo_code={config?.promo_code || ""} />
          </div>
          {/* Add additional cards here */}
        </Slider>
      </div>
    </div>
  );
};

export default LitudianWrapped;
