import React from "react";
import html2canvas from "html2canvas";
import { FiShare2 } from "react-icons/fi";
import { titleCase } from "./../utils/index";

const Favorites = (props) => {
  const shareDivAsImage = async () => {
    const element = document.getElementById("favoritesDiv");

    // Capture the div as an image
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png");

    // Check if Web Share API is supported
    if (navigator.share) {
      try {
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], "shared-image.png", {
          type: "image/png",
        });

        await navigator.share({
          title: "Litudian Wrapped",
          text: "Check out this content!",
          files: [file],
        });
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      // Fallback: open the image in a new tab
      const newWindow = window.open();
      newWindow.document.write(`<img src="${image}" alt="Shared Image"/>`);
    }
  };

  const { products = [], persona } = props;

  return (
    <div
      id="favoritesDiv"
      className="relative flex flex-col lg:flex-row h-screen w-full text-center p-4 bg-cover bg-center"
      style={{ backgroundImage: `url('/background.svg')` }}
    >
      {/* Left Section */}
      <div className="lg:w-1/4 w-full bg-[#fa9d76] relative">
        <div className="absolute bottom-10 left-4">
          <img src="./star.svg" alt="Star" className="h-20 w-20" />
          <p className="font-semibold text-orange-900 text-2xl lg:text-4xl">
            {persona}
          </p>
        </div>
      </div>

      {/* Main Content */}
      <div className="lg:w-1/2 w-full p-6 flex flex-col items-center justify-center">
        <h1 className="font-semibold text-4xl lg:text-6xl text-[#ff4c00] mb-3">
          Your Favorite Products
        </h1>
        <p className="font-semibold text-lg lg:text-xl text-orange-500 mb-6">
          You couldn't get enough of these items
        </p>

        {/* Categories List */}
        <div className="space-y-4 mt-4 flex flex-col items-center">
          {/* Category Item */}
          {products?.length > 0 &&
            products?.map((p, i) => (
              <div
                key={i}
                className="flex text-center justify-center items-center"
              >
                <div className="relative mr-8 items-center text-center">
                  {/* Background circles */}
                  <div className="absolute w-24 h-24 bg-orange-200 rounded-full -left-8 -top-8"></div>
                  <div className="absolute w-24 h-24 bg-orange-200 rounded-full -right-8 -bottom-8"></div>
                  {/* Image container with border */}
                  <div
                    className={`relative w-28 h-28 bg-white ${
                      i % 2 === 0 ? "rounded-sm" : "rounded-full"
                    } border-4 border-orange-500 flex items-center justify-center overflow-hidden z-10`}
                  >
                    <img
                      src={p?.image}
                      alt={titleCase(p?.name)}
                      className="w-28 h-28 object-cover"
                    />
                  </div>
                </div>
                <p className="text-white text-center ml-2 text-xl lg:text-2xl">
                  {titleCase(p?.name)}
                </p>
              </div>
            ))}
        </div>
      </div>

      {/* Right Section */}
      <div className="lg:w-1/4 w-full bg-[#fa9d76] relative">
        <div className="absolute top-10 left-4">
          <img src="./star.svg" alt="Star" className="h-20 w-20" />
          <p className="font-semibold text-orange-900 text-2xl lg:text-4xl">
            {persona}
          </p>
        </div>
      </div>
      {/* Share Icon */}
      <div className=" absolute bottom-4 mx-auto left-[50%] z-10">
        <button
          onClick={shareDivAsImage}
          className="text-white bg-black bg-opacity-50 rounded-full p-2"
        >
          <FiShare2 size={24} />
        </button>
      </div>
    </div>
  );
};

export default Favorites;
