import React from 'react';

const Thankyou = (props) => {
  const {promo_code} = props
    return (
        <div
        className="flex flex-col items-center justify-center h-screen w-full text-white  p-4 bg-cover bg-center"
        style={{ backgroundImage: `url('favorites.svg')` }}
      > 
      <div>
        <h1 className='text-6xl font-semibold text-[#ff4c00]'>Thank You</h1>
        <p>for being part of Litudian family.</p>

        <p className='mt-8 text-white text-3xl'>
            We look foward to serving you in 2025 with even more exciting shipments and experiences.
        </p>
        <p className='mt-8 text-white text-2xl'>A little token from us to you<br/><h1 className='text-3xl font-semibold'>*Promo code {promo_code}</h1></p>
        <p className='mt-8 text-white text-2xl'><a href="https://www.litudian.com" target="_blank" rel="noopener noreferrer"> Continue to shopping..</a></p>
        <p>&nbsp;</p>
        <p>* valid across all our catalogue till 31st October 2024</p>
      </div>
      
            
        </div>
    );
}

export default Thankyou;
