import React from 'react';
import html2canvas from 'html2canvas';
import { FiShare2 } from 'react-icons/fi'; // Importing the share icon

const Feedback = () => {
  const shareDivAsImage = async () => {
    const element = document.getElementById('feedbackDiv');

    // Capture the div as an image
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL('image/png');

    // Check if Web Share API is supported
    if (navigator.share) {
      try {
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], 'shared-image.png', { type: 'image/png' });

        await navigator.share({
          title: 'Litudian Feedback',
          text: 'Check out what others are saying about Litudian!',
          files: [file],
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      // Fallback: open the image in a new tab
      const newWindow = window.open();
      newWindow.document.write(`<img src="${image}" alt="Shared Image"/>`);
    }
  };

  return (
    <div
      id="feedbackDiv"
      className="relative flex flex-col items-center justify-center h-screen w-full text-white p-4 bg-cover bg-center"
      style={{ backgroundImage: `url('moments.svg')` }}
    >
     

      <div className="text-center">
        <h1 className='text-4xl md:text-6xl text-[#ff4c00] mb-4'>What Others Are Saying</h1>
        <p className='text-base md:text-2xl text-orange-900'>Here's what other Litudian customers have to say about their shipping experiences.</p>

        <div className='flex flex-col space-y-6 mt-8'>
          <div className='text-center'>
            <p className='text-lg md:text-xl text-gray-900 font-semibold'>"The products were of good quality, delivery was on time and in good condition... thank you will definitely be ordering again."</p>
            <p className='text-xl md:text-2xl text-orange-900'>Lyn Ngamau</p>
          </div>
          <div className='text-center'>
            <p className='text-lg md:text-xl text-gray-900 font-semibold'>"We absolutely love their services, the level of professionalism and attention to detail that they operate the business is excellent."</p>
            <p className='text-xl md:text-2xl text-orange-900'>Sue O. O</p>
          </div>
          <div className='text-center'>
            <p className='text-lg md:text-xl text-gray-900 font-semibold'>"Nothing beats reliability,  convenience and transparency and that is what Litudian offers. I have shipped over 7 times just with a single click on my phone."</p>
            <p className='text-xl md:text-2xl text-orange-900'>Caroline Kavita</p>
          </div>
        </div>
      </div>
         {/* Share Icon */}
         <div className=" absolute bottom-4 mx-auto z-10">
        <button 
          onClick={shareDivAsImage} 
          className="text-white bg-black bg-opacity-50 rounded-full p-2">
          <FiShare2 size={24} />
        </button>
      </div>
    </div>
  );
}

export default Feedback;
