import React from "react";
import { FiShare2 } from "react-icons/fi";
import html2canvas from "html2canvas";
import { titleCase } from "./../utils/index";

const Highlights = (props) => {
  const shareDivAsImage = async () => {
    const element = document.getElementById("highlightsDiv");
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png");

    if (navigator.share) {
      try {
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], "shared-image.png", {
          type: "image/png",
        });

        await navigator.share({
          title: "Litudian Highlights",
          text: "Check out the highlights from Litudian!",
          files: [file],
        });
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      const newWindow = window.open();
      newWindow.document.write(`<img src="${image}" alt="Shared Image"/>`);
    }
  };

  const { categories = [] } = props;

  return (
    <div
      id="highlightsDiv"
      className="relative flex flex-col items-center justify-center h-screen w-full text-white font-bold p-4 bg-cover bg-center"
      style={{ backgroundImage: `url('highlights-bg.svg')` }}
    >
      <div className="text-center">
        <h1 className="text-3xl md:text-6xl text-white font-semibold mb-3">
          Litudian Highlights
        </h1>
        <p className="text-sm md:text-2xl text-[#ff4c00] mb-8">
          From electronics to fashion, see which categories were the most
          popular among all Litudian customers this year.
        </p>

        <div className="space-y-3 mt-4 flex flex-col items-center">
          {/* Category Item */}
          {categories?.length > 0 &&
            categories?.map((c, i) => (
              <div
                key={i}
                className="flex text-center justify-center items-center"
              >
                <div className="relative mr-8 items-center text-center">
                  {/* Background circles */}
                  <div className="absolute w-24 h-24 bg-orange-200 rounded-full -left-6 md:-left-8 -top-6 md:-top-8"></div>
                  <div className="absolute w-24 h-24 bg-orange-200 rounded-full -right-6 md:-right-8 -bottom-6 md:-bottom-8"></div>
                  {/* Image container with border */}
                  <div className="relative w-28 h-28 bg-white rounded-full border-4 border-orange-500 flex items-center justify-center overflow-hidden z-10">
                    <img
                      src={c?.image}
                      alt={titleCase(c?.name)}
                      className="w-28 h-28 object-cover"
                    />
                  </div>
                </div>
                <p className="text-white text-center ml-2 text-lg md:text-2xl">
                  {titleCase(c?.name)}
                </p>
              </div>
            ))}
        </div>

        <p className="text-[#ff4c00] text-sm md:text-2xl mt-10 md:mt-20">
          These were the top categories that made this year special for Litudian
          shoppers.
        </p>
      </div>
      {/* Share Icon */}
      <div className=" absolute bottom-4 mx-auto z-10">
        <button
          onClick={shareDivAsImage}
          className="text-white bg-black bg-opacity-50 rounded-full p-2"
        >
          <FiShare2 size={24} />
        </button>
      </div>
    </div>
  );
};

export default Highlights;
