
import React from 'react';

const Intro2 = () => {

  return (
    <div
      id="intro2Div"
      className="relative flex flex-col items-center justify-center h-screen w-full text-white text-center bg-cover bg-center"
      style={{ backgroundImage: `url('/intro2-bg.svg')` }}
    >
     

      <h1 className='font-semibold text-4xl md:text-8xl mb-4'>
        Your Years with Litudian
      </h1>
      <p className='font-medium text-lg md:text-xl'>
        Discover your unique shopping journey with us.
      </p>
    </div>
  );
}

export default Intro2;
