import React from 'react';

const Intro = (props) => {
  const {name} = props
  return (
    <div
      id="introDiv"
      className="relative flex flex-col items-center justify-center h-screen w-full bg-cover bg-center overflow-hidden"
      style={{ backgroundImage: `url('/bg.svg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
    

      <h1 className='font-semibold text-4xl md:text-8xl mb-4 text-white text-center'>
       {name} Litudian Wrapped
      </h1>
      <div className='bg-white rounded-full h-60 w-60 flex items-center justify-center'>
        <img src='./litudian-logo.svg' alt='Logo' className="max-w-full max-h-full" />
      </div>
      <h1 className='text-3xl font-semibold md:text-8xl mb-4 text-white text-center'>#LitudianWrapped</h1>
      <p className='font-medium text-lg md:text-xl text-white'>
        (Swipe screen for more >>>>)
      </p>
    </div>
  );
}

export default Intro;
