import React from 'react';
import html2canvas from 'html2canvas';
import { FiShare2 } from 'react-icons/fi';

const Persona = (props) => {
  const shareDivAsImage = async () => {
    const element = document.getElementById('personaDiv');
    
    // Capture the div as an image
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL('image/png');

    // Check if Web Share API is supported
    if (navigator.share) {
      try {
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], 'shared-image.png', { type: 'image/png' });

        await navigator.share({
          title: 'Litudian Wrapped',
          text: 'Check out this content!',
          files: [file],
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      // Fallback: open the image in a new tab
      const newWindow = window.open();
      newWindow.document.write(`<img src="${image}" alt="Shared Image"/>`);
    }
  };

  const {persona = ""} = props

  return (
    <div
      id="personaDiv"
      className="relative flex flex-col items-center justify-center h-screen w-full text-white text-center p-4 bg-cover bg-center"
      style={{ backgroundImage: `url('/persona.svg')` }}
    >
   

      <h1 className='font-semibold text-4xl md:text-6xl text-orange-600 mb-3'>
        Meet your Shopping Persona
      </h1>
      <p className='font-semibold text-lg md:text-xl text-orange-900'>
        Whether you love gadgets or fashion, see how your choices define you.
      </p>
      <p className='text-orange-900 font-medium mt-3 text-lg md:text-xl'>
        Based on your shopping habits, you are <span className='text-orange-500 font-semibold'>{persona}</span>
      </p>

         {/* Share Icon */}
         <div className=" absolute bottom-4 mx-auto z-10">
        <button 
          onClick={shareDivAsImage} 
          className="text-white bg-black bg-opacity-50 rounded-full p-2">
          <FiShare2 size={24} />
        </button>
      </div>
    </div>
  );
}

export default Persona;
