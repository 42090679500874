import React from "react";
import html2canvas from "html2canvas";
import { FiShare2 } from "react-icons/fi";
import { titleCase } from "./../utils/index";

const Categoriez = (props) => {
  const shareDivAsImage = async () => {
    const element = document.getElementById("categoriezDiv");

    // Capture the div as an image
    const canvas = await html2canvas(element);
    const image = canvas.toDataURL("image/png");

    // Check if Web Share API is supported
    if (navigator.share) {
      try {
        const blob = await (await fetch(image)).blob();
        const file = new File([blob], "shared-image.png", {
          type: "image/png",
        });

        await navigator.share({
          title: "Litudian Wrapped",
          text: "Check out this content!",
          files: [file],
        });
      } catch (error) {
        console.error("Error sharing", error);
      }
    } else {
      // Fallback: open the image in a new tab
      const newWindow = window.open();
      newWindow.document.write(`<img src="${image}" alt="Shared Image"/>`);
    }
  };

  const { categories = [] } = props;

  return (
    <div
      id="categoriezDiv"
      className="relative flex flex-col items-center justify-center h-screen w-full text-white text-center p-4 bg-cover bg-center"
      style={{ backgroundImage: `url('/highlights-bg.svg')` }}
    >
      <h1 className="font-semibold text-4xl md:text-6xl text-white mb-3">
        Your Top Categories
      </h1>
      <p className="font-semibold text-lg md:text-xl text-[#ff4c00] mb-3">
        These categories made up the majority of your shipments in the last 3
        years.
      </p>

      {/* Categories List */}
      <div className="space-y-4 mt-4 flex flex-col items-center p-8">
        {/* Category Item */}
        {categories.length > 0 &&
          categories.map((c, i) => (
            <div
              key={i}
              className={`flex text-center justify-center items-center`}
            >
              <div className="relative mr-8 items-center text-center">
                {/* Background circles */}
                <div className="absolute w-24 h-24 bg-orange-200 rounded-full -left-8 -top-8"></div>
                <div className="absolute w-24 h-24 bg-orange-200 rounded-full -right-8 -bottom-8"></div>
                {/* Image container with border */}
                <div
                  className={`relative w-28 h-28 bg-white ${
                    i % 2 === 0 ? "rounded-sm" : "rounded-full"
                  } border-4 border-orange-500 flex items-center justify-center overflow-hidden z-10`}
                >
                  <img
                    src={c?.image}
                    alt={titleCase(c?.name)}
                    className="w-28 h-28 object-cover"
                  />
                </div>
              </div>
              <p className="text-white text-center ml-2 text-xl lg:text-2xl normal-case">
                {titleCase(c?.name)}
              </p>
            </div>
          ))}
      </div>
      {/* Share Icon */}
      <div className=" absolute bottom-4 mx-auto z-10">
        <button
          onClick={shareDivAsImage}
          className="text-white bg-black bg-opacity-50 rounded-full p-2"
        >
          <FiShare2 size={24} />
        </button>
      </div>
    </div>
  );
};

export default Categoriez;
